<template>
  <div>
    <div class="page-name">
      <span>
        <i class="fas fa-map-marker-alt"></i>
        {{ i18n("Schedule.ScheduleManagement") }}
      </span>
    </div>

    <div class="d-flex justify-content-between align-items-center mb-2">
      <span class="text-danger"> {{ i18n("Schedule.AfterSettingSchedule") }}</span>
      <div>
        <button
          class="btn btn-sm btn-secondary mr-2"
          data-toggle="modal"
          data-target="#logModal"
        >
          {{ i18n("Schedule.GetLOG") }}
        </button>
        <button class="btn btn-sm btn-warning mr-2" @click="getSchedules">
          {{ i18n("Schedule.VerificationStatus") }}
        </button>
        <button
          class="btn btn-sm btn-danger"
          data-toggle="modal"
          data-target="#resetModal"
        >
          {{ i18n("Schedule.ResetScheduleStatus") }}
        </button>
      </div>
    </div>

    <el-table :data="schedules" stripe style="width: 100%">
      <el-table-column prop="Name" :label="i18n('Schedule.ScheduleName')">
      </el-table-column>
      <el-table-column :label="i18n('Schedule.LastBootTime')">
        <template slot-scope="scope">
          {{ scope.row.LastStartTime | dateTimeFormat }}
        </template>
      </el-table-column>
      <el-table-column :label="i18n('Schedule.LastEndTime')">
        <template slot-scope="scope">
          {{ scope.row.LastEndTime | dateTimeFormat }}
        </template>
      </el-table-column>
      <el-table-column prop="Status" :label="i18n('Basic.State')"> </el-table-column>
      <el-table-column :label="i18n('Custom.Function')">
        <template slot-scope="scope">
          <button
            class="btn btn-sm btn-blue"
            :disabled="scope.row.Status === 'Running'"
            @click="startSchedule(scope.row.Code)"
          >
            {{ i18n("Schedule.ExecutionSchedule") }}
          </button>

          <button
            class="btn btn-sm btn-info ml-2"
            @click.prevent="openCronModal(scope.row)"
          >
            {{ i18n("Schedule.ScheduleTimeSetting") }}
          </button>
        </template>
      </el-table-column>
    </el-table>

    <!-- Reset Modal -->
    <div class="modal fade" id="resetModal" tabindex="-1" role="dialog">
      <div class="modal-dialog modal-dialog-centered" role="document">
        <form class="modal-content" @submit.prevent="resetStatus">
          <div class="modal-header">{{ i18n("Schedule.ResetScheduleStatus") }}</div>

          <div class="modal-body">
            <div class="alert-box">
              <div class="alert-icon">!</div>
              <div class="alert-text">
                <p>{{ i18n("Schedule.Tip") }}</p>
              </div>
            </div>
          </div>

          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-dismiss="modal">
              {{ i18n("Basic.Close") }}
            </button>
            <button type="submit" class="btn btn-danger" @click.prevent="resetStatus">
              {{ i18n("Basic.Confirm") }}
            </button>
          </div>
        </form>
      </div>
    </div>

    <!-- LOG Modal -->
    <div class="modal fade" id="logModal" tabindex="-1" role="dialog">
      <div class="modal-dialog modal-dialog-centered" role="document">
        <form class="modal-content" @submit.prevent="getLOG">
          <div class="modal-header">{{ i18n("Schedule.GetLOG") }}</div>

          <div class="modal-body">
            <div class="row">
              <div class="col-md-4">
                <div class="field">
                  <div class="field-name">{{ i18n("Schedule.SelectDate") }}</div>
                  <el-date-picker
                    value-format="yyyy-MM-dd"
                    v-model="selectDate"
                    type="date"
                    placeholder="Select date"
                  >
                  </el-date-picker>
                </div>
              </div>
            </div>
          </div>

          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-dismiss="modal">
              {{ i18n("Basic.Close") }}
            </button>
            <button type="submit" class="btn btn-blue">
              {{ i18n("Custom.Download") }}
            </button>
          </div>
        </form>
      </div>
    </div>

    <!-- cron modal -->
    <el-dialog
      :title="i18n('Custom.ScheduleTimeSetting')"
      class="cron-modal"
      :visible.sync="cronDialogVisible"
      width="80%"
    >
      <VueCronEditorBuefy
        cronSyntax="quartz"
        :preserveStateOnSwitchToAdvanced="true"
        :visibleTabs="visibleTabs"
        v-model="scheduleDetail.CronExpression"
        locale="zh_TW"
        :custom-locales="{ zh_TW }"
      ></VueCronEditorBuefy>

      Quartz Cron:{{ scheduleDetail.CronExpression }}

      <span slot="footer" class="dialog-footer">
        <el-button type="info" @click="cronDialogVisible = false">{{ i18n("Basic.Cancel") }}</el-button>
        <el-button type="primary" @click="setCron()">{{ i18n("Basic.Confirm") }}</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<style lang="scss">
// .btn-yellow {
//   background: #cca836;
//   color: #fff;
// }

// .btn-lightblue {
//   color: #fff !important;
//   background: #707eb5;
// }

// .btn-blue {
//   color: #fff !important;
//   background: #343b55;
// }

// .mx-datepicker {
//   width: 255px !important;
// }

// .alert-text {
//   p {
//     margin-bottom: 0;
//   }
// }

.cron-modal {
  .card {
    background-color: transparent !important;
    border: none !important;
    box-shadow: none !important;

    & > .field {
      flex-wrap: wrap !important;


    }
    .field.is-grouped {
        margin-bottom: 5px !important;
      }
  }

  .icon.is-left {
    position: relative;

    &:after {
      content: url('data:image/svg+xml; utf8, <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><!--! Font Awesome Pro 6.2.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M232 120C232 106.7 242.7 96 256 96C269.3 96 280 106.7 280 120V243.2L365.3 300C376.3 307.4 379.3 322.3 371.1 333.3C364.6 344.3 349.7 347.3 338.7 339.1L242.7 275.1C236 271.5 232 264 232 255.1L232 120zM256 0C397.4 0 512 114.6 512 256C512 397.4 397.4 512 256 512C114.6 512 0 397.4 0 256C0 114.6 114.6 0 256 0zM48 256C48 370.9 141.1 464 256 464C370.9 464 464 370.9 464 256C464 141.1 370.9 48 256 48C141.1 48 48 141.1 48 256z"/></svg>');
      position: absolute;
      left: 10px;
      top: 50%;
      width: 20px;
      height: 20px;
      transform: translateY(-50%);
      opacity: 0.3;
    }
  }
}
</style>

<script>
import VueCronEditorBuefy from "vue-cron-editor-buefy";
import moment from "moment";
import $ from "jquery";
import { mapActions } from "vuex";
// import FileSaver from 'file-saver';

export default {
  data() {
    return {
      cronDialogVisible: false,

      zh_TW: {
        every: "每隔",
        mminutes: "分鐘",
        hoursOnMinute: "小時，於分鐘:",
        daysAt: "天，並在 小時/分:",
        at: "的，小時/分",
        onThe: "於月份的第",
        dayOfEvery: "號，中間每隔",
        monthsAt: "個月，並在 小時/分:",
        everyDay: "每",
        mon: "星期一",
        tue: "星期二",
        wed: "星期三",
        thu: "星期四",
        fri: "星期五",
        sat: "星期六",
        sun: "星期日",
        hasToBeBetween: "必須要在這兩者之間",
        and: "and",
        minutes: "分",
        hourly: "時",
        daily: "天",
        weekly: "周",
        monthly: "月",
        advanced: "進階設定",
        cronExpression: "Quartz Cron表達式:",
      },
      screen: null, // loading遮罩
      schedules: [],
      scheduleDetail: {
        CronExpression: null,
      },
      selectDate: "", //  LOG選擇的日期
    };
  },
  components: {
    VueCronEditorBuefy,
  },
  methods: {
    ...mapActions("sidebarModule"),
    // 多國語系
    i18n(keyStr) {
      return this.$t(keyStr);
    },
    getSchedules() {
      //  取得排程
      this.screen = this.openLoading();

      const url = `${window.BaseUrl.api}/Jobs`;

      this.schedules = [];

      this.$http
        .get(url)
        .then((response) => {
          if (response.data.ErrorMessage) {
            this.$notify({
              title: "錯誤",
              message: response.data.ErrorMessage,
              type: "error",
              duration: 0,
            });
          } else {
            this.schedules = response.data.Data;
          }
          this.screen.close();
        })
        .catch((error) => {
          this.$notify({
            title: "錯誤",
            message: error.response.data.ErrorMessage,
            type: "error",
            duration: 0,
          });
          this.screen.close();
        });
    },

    // 執行排程
    startSchedule(code) {
      this.screen = this.openLoading();
      const url = `${window.BaseUrl.api}/Jobs/Execute/${code}`;
      this.$http
        .post(url)
        .then((response) => {
          if (response.data.ErrorMessage) {
            this.$notify({
              title: "錯誤",
              message: response.data.ErrorMessage,
              type: "error",
              duration: 0,
            });
          } else {
            this.$store.dispatch("alertMessageModule/updateMessage", {
              message: "成功執行",
              status: "success",
            });

            setTimeout(() => {
              this.getSchedules();
            }, 500);
          }
          this.screen.close();
        })
        .catch((error) => {
          this.$notify({
            title: "錯誤",
            message: error.response.data.ErrorMessage,
            type: "error",
            duration: 0,
          });
          this.screen.close();
        });
    },

    // 重設排程狀態
    resetStatus() {
      this.screen = this.openLoading();
      const url = `${window.BaseUrl.api}/Jobs/Reset`;

      this.$http
        .post(url)
        .then((response) => {
          if (response.data.ErrorMessage) {
            this.$notify({
              title: "錯誤",
              message: response.data.ErrorMessage,
              type: "error",
              duration: 0,
            });
          } else {
            this.getSchedules();
            this.$store.dispatch("alertMessageModule/updateMessage", {
              message: "已成功執行重設狀態",
              status: "success",
            });
          }
          this.screen.close();
        })
        .catch((error) => {
          this.$notify({
            title: "錯誤",
            message: error.response.data.ErrorMessage,
            type: "error",
            duration: 0,
          });
          this.screen.close();
        });

      $("#resetModal").modal("hide");
    },
    //  取得LOG檔
    getLOG() {
      this.screen = this.openLoading();
      const url = `${window.BaseUrl.api}/Jobs/logsFile`;

      const params = {
        logDate: this.selectDate,
      };

      this.$http
        .get(url, { params })
        .then((response) => {
          if (response.data.ErrorMessage) {
            this.$notify({
              title: "錯誤",
              message: response.data.ErrorMessage,
              type: "error",
              duration: 0,
            });
          } else {
            const a = document.createElement("a"); // Create <a>
            a.href = `data:${response.data.Data.MimeType};base64,${response.data.Data.FileBase64}`; // Image Base64 Goes here
            a.download = response.data.Data.FileName; // File name Here
            a.click(); // Downloaded file

            this.$store.dispatch("alertMessageModule/updateMessage", {
              message: "下載成功",
              status: "success",
            });
          }

          this.screen.close();
        })
        .catch(() => {
          this.$store.dispatch("alertMessageModule/updateMessage", {
            message: "下載失敗",
            status: "danger",
          });

          this.screen.close();
        });

      // this.selectDate = moment().format("YYYY-MM-DD");

      $("#logModal").modal("hide");
    },

    // 開啟排程設定modal
    openCronModal(schedule) {
      this.scheduleDetail = { ...schedule };
      this.cronDialogVisible = true;
    },

    //排程設定儲存
    setCron() {
      this.screen = this.openLoading();
      const url = `${window.BaseUrl.api}/Jobs/${this.scheduleDetail.Guid}`;

      console.log("cronExpression", this.scheduleDetail.CronExpression);

      const obj = {
        CronExpression: this.scheduleDetail.CronExpression,
      };

      this.$http
        .put(url, obj)
        .then((response) => {
          if (response.data.ErrorMessage) {
            this.$notify({
              title: "錯誤",
              message: response.data.ErrorMessage,
              type: "error",
              duration: 0,
            });
          } else {
            this.getSchedules();
            this.$store.dispatch("alertMessageModule/updateMessage", {
              message: "成功編輯",
              status: "success",
            });
            this.cronDialogVisible = false;
          }
        })
        .catch(() => {
          this.$notify({
            message: "失敗",
            type: "error",
            duration: 0,
          });
          this.screen.close();
        });
    },
  },
  created() {
    this.getSchedules();

    this.selectDate = moment().format("YYYY-MM-DD");
  },
};
</script>
